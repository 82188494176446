import React, { useEffect, useState } from 'react';
import { Firestore, doc, onSnapshot } from 'firebase/firestore';
import '../css/UserCountListener.css';

interface UsersCountListenerProps {
  db: Firestore;
}

const UsersCountListener: React.FC<UsersCountListenerProps> = ({ db }) => {
  const [trueCount, setTrueCount] = useState<number>(0);

  useEffect(() => {
    // Reference the "usersCount" document with id "users" in Firestore
    const docRef = doc(db, 'usersCount', 'users');

    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(
      docRef,
      (docSnap) => {
        if (docSnap.exists()) {
          const data = docSnap.data();
          // Count fields with value === true
          const count = Object.values(data).filter((value) => value === true).length;
          setTrueCount(count);
        } else {
          setTrueCount(0);
        }
      },
      (error) => {
        console.error('Error listening to usersCount document:', error);
      }
    );

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [db]);

  // Calculate slider percentage (capped at 100)
  const sliderPercentage = trueCount <= 100 ? trueCount : 100;
  const ratio = sliderPercentage / 100;
  // Interpolate from red (255,0,0) to green (0,255,0)
  const r = Math.round(255 * (1 - ratio));
  const g = Math.round(255 * ratio);
  const sliderColor = `rgb(${r}, ${g}, 0)`;

  return (
    <div className="users-count-container">
      <h2 className="users-count-title"> Users Subscribed</h2>
  
      <p className="users-count-number">{trueCount}</p>
      <div className="slider-container">
        <div
          className="slider-fill"
          style={{ width: `${sliderPercentage}%`, background: sliderColor }}
        ></div>
        
      </div>
      {trueCount > 100 && (
        <p className="users-count-extra">Count: {trueCount}</p>
        
      )}
          <h2>Phase I</h2>
      <ul style={{textAlign:"left"}}>
        <li>✅ NFT aidrop</li>
        <li>✅ Get Notified via email of strategic project news</li>


        </ul>
    </div>
  );
};

export default UsersCountListener;
