import React, { useState } from 'react';
import '../css/LandingPage.css'; // Import the CSS file

const LandingPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [wallet, setWallet] = useState('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const CLOUD_FUNCTION_URL = 'https://registeruser-veo4dho2tq-uc.a.run.app';

      // The wallet field is optional, so we send it only if provided.
      const response = await fetch(CLOUD_FUNCTION_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, wallet: wallet || null }),
      });

      if (response.ok) {
        alert('Success! Please check your email for the authentication link.');
      } else {
        const errorText = await response.text();
        alert(`There was an error: ${errorText}`);
      }
    } catch (error) {
      console.error(error);
      alert('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="landing-page">
     <div style={{textAlign:"left"}}> <h1>Register to be airdropped with NFTs</h1>
      <p>1️⃣Enter your details to receive an auth link 🔗</p>
      <p id="#info">2️⃣Add your wallet to be airdoped with NFTs</p></div>
      <form onSubmit={handleSubmit}>
        <div className="input-group">
          <span className="icon">&#9993;</span> {/* Email Icon */}
          <input
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <span className="icon">&#128176;</span> {/* Wallet Icon */}
          <input
            type="text"
            placeholder="Your solana wallet address"
            value={wallet}
            onChange={(e) => setWallet(e.target.value)}
          />
        </div>
        <p>You accept our <a href="/privacy_policy">Privacy Policy</a> & <a href="/terms_and_conditions">Terms and Conditions</a> </p>
        <button type="submit">Send Link</button>
      </form>
    </div>
  );
};

export default LandingPage;
