// src/TermsAndConditions.tsx

import React from 'react';

const TermsAndConditions: React.FC = () => {
  return (
    <div
      className="terms-container"
      style={{
        padding: '2rem',
        maxWidth: '800px',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
      }}
    >
      <h1>Terms and Conditions</h1>
      <p>
        <em>Last Updated: 03-20-2025</em>
      </p>

      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing and using this website, you agree to be bound by these Terms and Conditions.
        If you do not agree with any part of these terms, please do not use our website.
      </p>

      <h2>2. Changes to Terms</h2>
      <p>
        We reserve the right to update or modify these Terms and Conditions at any time without
        prior notice. Your continued use of the website constitutes your acceptance of any such
        changes.
      </p>

      <h2>3. Use of the Site</h2>
      <p>
        You agree to use the website only for lawful purposes and in a way that does not infringe
        the rights of, restrict, or inhibit anyone else's use and enjoyment of the website.
      </p>

      <h2>4. Intellectual Property</h2>
      <p>
        All content on this website—including text, graphics, logos, images, and data—is the property
        of the site owner and is protected by copyright and other intellectual property laws.
      </p>

      <h2>5. Limitation of Liability</h2>
      <p>
        In no event shall the site owner be liable for any direct, indirect, incidental, or
        consequential damages arising out of your use of, or inability to use, this website.
      </p>

   

   
    </div>
  );
};

export default TermsAndConditions;
