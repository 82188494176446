import React, { useEffect, useState } from 'react';
import { FirebaseApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import '../css/Collection.css';

type Collection = {
  image: string;
  title: string;
  soldPercentage: number;
  totalItems: number;
  soldItems: number;
  description: string;
  href: string;
};

type CollectionsProps = {
  firebaseApp: FirebaseApp;
};

const Collections: React.FC<CollectionsProps> = ({ firebaseApp }) => {
  const [collections, setCollections] = useState<Collection[]>([]);
  const db = getFirestore(firebaseApp);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'collections'));
        const collectionsData = querySnapshot.docs.map((doc) => doc.data() as Collection);
        setCollections(collectionsData);
      } catch (error) {
        console.error('Error fetching collections:', error);
      }
    };

    fetchCollections();
  }, [db]);

  return (
    <div className="collections-container">
      <h2 className="collections-title">Latest</h2>
      <div className="collections-list">
        {collections.map((collection, index) => (
          <div key={index} className="collection-card">
            <a href={collection.href}>
              <img src={collection.image} alt={collection.title} className="collection-image" />
              <div className="collection-details">
                <h3 className="collection-title">{collection.title}</h3>
                <div className="progress-bar">
                  <div
                    className="progress"
                    style={{ width: `${collection.soldPercentage}%` }}
                  ></div>
                </div>
                <div className="sold-info">
                  <span>{collection.soldPercentage.toFixed(2)}% Sold</span>
                  <span>{collection.soldItems}/{collection.totalItems}</span>
                </div>
                <p className="collection-description">{collection.description}</p>
                <button>Mint</button>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Collections;
