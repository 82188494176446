import { WalletContextProvider } from './WallletContext';
import { WalletMultiButton, WalletDisconnectButton } from '@solana/wallet-adapter-react-ui';
import { FaSearch, FaWallet, FaBars } from 'react-icons/fa';
import { ConnectionProvider, WalletProvider, useConnection, useWallet } from '@solana/wallet-adapter-react';
import app from './firebase';
import { getFunctions } from 'firebase/functions';
import LandingPage from './components/LandingPage';
import UsersCountListener from './components/UserCountListener';
import './css/style.css'; // Assuming this will hold the provided styles plus adjustments
import React, { useMemo, useState, useCallback, FC } from 'react';
import MinimalMenu from "./components/MinimalMenu"
import logo from "./img/logo.jpg"
import { PublicKey, Transaction } from '@solana/web3.js';
import Collections from './components/Collections'; // Import your Collections component
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import sampleImage from "./img/AClass.jpeg"
import NFTChecker from './components/NFTChecker';
import FAQ from './components/FAQ';
import { httpsCallable } from 'firebase/functions';
//import { functions } from './firebaseFunctions';
import { collection, getDocs, setDoc,getFirestore, doc, getDoc, onSnapshot, updateDoc,DocumentData } from "firebase/firestore";
import { Connection } from '@solana/web3.js';
import Verify from './components/Verify';
import WhitePaper from './components/WhitePaper';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';

type Collection = {
  image: string;
  title: string;
  soldPercentage: number;
  totalItems: number;
  soldItems: number;
  description: string;
};
const connection = new Connection("https://mainnet.helius-rpc.com/?api-key=c7d596e0-04e2-4588-89c2-d862cec3c8a0")
const functions = getFunctions(app, 'us-central1'); // Update region if necessary

const App: FC = () => {
    //const wallet = useWallet();
   // const { publicKey, sendTransaction, signTransaction } = useWallet();
   const { publicKey, sendTransaction, signTransaction } = useWallet();
   const wallet = useWallet();
   const [tokensClaimedS, setTokensClaimedS] = useState("")


   const db = getFirestore(app);

   // const { publicKey, sendTransaction, signTransaction } = useWallet();
   interface MintResponse {
    tx: string;  // tx should be a base64 encoded string
  }
  const [isClaimTokensB, setIsClaimTokensB]= useState(true);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const toggleDrawer = () => {
      setIsDrawerOpen(!isDrawerOpen);
  };
  let coll1: Collection= {image: sampleImage.toString(), title: "A Class", soldPercentage:2, totalItems: 1000, description: "A Class a Ticket to Metaverse", soldItems: 0 }
  //https://arweave.net/uhZiV9AMZMNxA4h5tHGSK_GHWPtLd7FXuUrPnXYuxvs
  let coll2: Collection = {image: "https://arweave.net/uhZiV9AMZMNxA4h5tHGSK_GHWPtLd7FXuUrPnXYuxvs", title: "Antropomorphic", soldPercentage:33, totalItems: 1000, description: "An antropomorphic god", soldItems: 330 }
  let sampleCollections: Collection[] = []
  sampleCollections.push(coll1)
  sampleCollections.push(coll2)

  // Function to mint the NFT and send 0.01 SOL
const claimTokensF = async (walletPublicKey: PublicKey, sendTransaction: any, signTransaction: any, isAirdrop: boolean) => {
    try {
      setIsClaimTokensB(true)
  
    
  
  
      // Call the Firebase Cloud Function
      const mintCNFTFunction = httpsCallable(functions, 'claimTokens');
      const result = await mintCNFTFunction({wallet: walletPublicKey.toString()});
  
      // Extract the serialized transaction from the result
      const { tx: serializedTx } = result.data as MintResponse;
  
      // Deserialize the transaction
      const tx = Transaction.from(Buffer.from(serializedTx, 'base64'));
  
  const signature = await sendTransaction(tx, connection);
  
      await connection.confirmTransaction(signature, 'confirmed');
  
  //delete data
  const tokensClaimed = httpsCallable(functions, 'tokensClaimed');
  const result2 = await tokensClaimed({wallet: walletPublicKey.toString()});
  
  //set a message
  setTokensClaimedS("✅ Tokens Claimed")
  setIsClaimTokensB(true)
      console.log('Transaction successful with signature:', signature);
    } catch (error) {
      console.error('Error minting NFT or sending transaction:', error);
    }
  }

  const claimTokens = useCallback(async () => {
    if (!publicKey) {
      alert('Wallet not connected!');
      return;
    }
    
  
    // Call the mint function and pass the `signTransaction` function
    await claimTokensF(publicKey, sendTransaction, signTransaction, true);
  }, [publicKey, sendTransaction, signTransaction]);


  const handleMintClick = useCallback(async () => {
    if (!publicKey) {
      alert('Wallet not connected!');
      return;
    }
  
    // Call the mint function and pass the `signTransaction` function
    await mintCNFT(publicKey, sendTransaction, signTransaction, false);
  }, [publicKey, sendTransaction, signTransaction]);
    // Define wallet adapters
// Function to mint the NFT and send 0.01 SOL
const mintCNFT = async (walletPublicKey: PublicKey, sendTransaction: any, signTransaction: any, isAirdrop: boolean) => {
    try {
  
      
      if (isAirdrop){
        //pay tx
        let lamports =.002;
  
    
        //alert("Pay for token account")
        
    /*    let lamportsI = LAMPORTS_PER_SOL*lamports;
        console.log(walletPublicKey.toBase58());
        console.log("lamports sending: {}", thelamports)
        const transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: walletPublicKey,
                toPubkey: new PublicKey("qUaChgT3LwNyDrdpoafHNaZbLweKC4KytAtihtSGotK"),
                lamports: lamportsI,
            })
        );
    
        const signature = await sendTransaction(transaction, connection);
        
        return*/
      }
  
  
      // Call the Firebase Cloud Function
      const mintCNFTFunction = httpsCallable(functions, 'mintOnlyCNFT');
      const result = await mintCNFTFunction({wallet: walletPublicKey.toString()});
  
      // Extract the serialized transaction from the result
      const { tx: serializedTx } = result.data as MintResponse;
  
      // Deserialize the transaction
      const tx = Transaction.from(Buffer.from(serializedTx, 'base64'));
  
      // Define the recipient wallet to send 0.01 SOL
      //const recipientPublicKey = new PublicKey('qUaChgT3LwNyDrdpoafHNaZbLweKC4KytAtihtSGotK');
  
  
      // Create a connection to the Solana cluster
  
      // Sign the transaction using the wallet adapter's `signTransaction`
      //const signedTx = await signTransaction(tx);
  
      // Send the signed transaction using the wallet adapter's `sendTransaction`
      //      const signature = await sendTransaction(transaction, connection);
  
  //    const signature = await sendTransaction(signedTx, connection);
  const signature = await sendTransaction(tx, connection);
  
      await connection.confirmTransaction(signature, 'confirmed');
  
      const unsub = onSnapshot(doc(db, "minted", wallet.publicKey!.toString()), (doc) => {
        console.log("Current data: ", doc.data());
        setIsClaimTokensB(false)
    });
      
  
      console.log('Transaction successful with signature:', signature);
    } catch (error) {
      console.error('Error minting NFT or sending transaction:', error);
    }
  }
    
  return (
    <ConnectionProvider endpoint="https://api.mainnet-beta.solana.com">
      <WalletProvider wallets={[]} autoConnect>
      <WalletContextProvider>
                <Router>
                    <div className="App">
                        <header className="menu-container">
                            <div className="logo">
                                <img src={logo} alt="Logo" className="logo-icon" />
                            </div>
                            <nav className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
                                <a href="/collections">Collections</a>
                                <a href="/nft-holder">NFT Holder (Rewards)</a>
                                <a href="/faq">FAQ</a>
                                <a href="/support">Support</a>
                            </nav>
                            <div className="menu-icons">
                                <FaSearch className="icon" />
                                <FaWallet className="icon" />
                                <WalletMultiButton />
                                <FaBars className="hamburger" onClick={toggleMenu} />
                            </div>
                        </header>
                        <div >

    </div>
                        <Routes>
                            <Route path="/collections" element={<Collections firebaseApp={app} />} />
                            <Route path="/nft-holder" element={<NFTChecker />} />
                            <Route path="/faq" element={<FAQ />} />
                            <Route path="/verify" element={<Verify />} />
        <Route path="/white_paper" element={<WhitePaper />} />

        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />

                                </Routes>
                        <LandingPage></LandingPage>
                        <UsersCountListener db ={db}></UsersCountListener>
                        <div><center><a href='/white_paper'>White Paper</a></center></div>

                    </div>
                </Router>
            </WalletContextProvider>
        </WalletProvider>
    </ConnectionProvider>

);
}

export default App;
