// src/Verify.tsx
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Verify: React.FC = () => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);

  // We’ll parse the query params like ?token=xxx&id=yyy
  const location = useLocation();

  useEffect(() => {
    // Helper to parse query string
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const id = params.get('id');

    const verifyUser = async () => {
      if (!token || !id) {
        setMessage('Missing token or user ID');
        setLoading(false);
        return;
      }
      try {
        const response = await fetch(
          'https://us-central1-asc-2-97b30.cloudfunctions.net/verifyUser',
          {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token, id }),
          }
        );
        const data = await response.json();
        if (response.ok) {
          setMessage(data.message || 'Verification successful!');
        } else {
          setMessage(data.message || 'Verification failed');
        }
      } catch (error) {
        console.error(error);
        setMessage('Error connecting to server');
      }
      setLoading(false);
    };

    verifyUser();
  }, [location.search]);

  if (loading) {
    return <p>Verifying your email...</p>;
  }

  return (
    <div>
      <h2>Verification Status</h2>
      <p>{message}</p>
    </div>
  );
};

export default Verify;
