import React, { useState } from 'react';
import '../css/FAQ.css';

type FAQItem = {
  question: string;
  answer: string;
};

const faqData: FAQItem[] = [
  {
    question: "⚡Earn Token Rewards Daily!",
    answer: "Earn Tokens by holding our Official NFTs and claim daily. (<a className='linkactive' href='/collections's>Buy NFTs here</a>) & (<a className='linkactive' href='/nft-holder'>Claim Tokens here</a>)",
  },
  {
    question: "Which blockchains are supported?",
    answer: "We support Solana.",
  },
  {
    question: "Fees for creating and minting?",
    answer: "We take 2.5% on each mint unless stated otherwise. Additional blockchain Solana fees apply based on the network.",
  },
  {
    question: "Do you support whitelists for collections?",
    answer: "Yes, whitelist functionality is available and can be edited on the mint page by creators once the collection is launched.",
  },
  {
    question: "Tokens + NFTs",
    answer: "We use NFTs for marketing purposes and community building, 50% of NFT sales on secondary marketplaces are used to buy more Tokens and provide liquidity for holders.",
  },
  // Add more FAQ items as needed
];

const FAQ: React.FC = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const toggleExpand = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className="faq-container">
      <h1 className="faq-title">FAQ</h1>
      <p className="faq-subtitle">Everything you need to get started</p>
      {faqData.map((item, index) => (
        <div
          key={index}
          className={`faq-item ${expandedIndex === index ? 'expanded' : ''}`}
          onClick={() => toggleExpand(index)}
        >
          <div className="faq-question">
            {item.question}
            <span className="faq-icon">{expandedIndex === index ? '-' : '+'}</span>
          </div>
          {expandedIndex === index && (
            <div className="faq-answer" dangerouslySetInnerHTML={{ __html: item.answer }}></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQ;
