// src/WhitePaper.tsx

import React from 'react';

const WhitePaper: React.FC = () => {
  return (
    <div
      className="whitepaper-container"
      style={{
        padding: '2rem',
        maxWidth: '800px',
        margin: '0 auto',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: '#333',
      }}
    >
      <h1 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>White Paper</h1>
      
      <section style={{ marginBottom: '1.5rem' }}>
        <h2>Build Learn to Earn Apps</h2>
        <p>
          Our mission is to create innovative "Learn to Earn" applications that empower users by combining educational content with real-world rewards. By integrating learning with incentivized participation, we help users grow their skills while earning tokens.
        </p>
      </section>

      <section style={{ marginBottom: '1.5rem' }}>
        <h2>NFT Rewards & Daily Token Claims</h2>
        <p>
          NFT holders can claim daily tokens by earning points through their engagement. The NFTs not only serve as digital collectibles but also act as reward multipliers, enhancing the value of the tokens you earn.
        </p>
      </section>

      <section style={{ marginBottom: '1.5rem' }}>
        <h2>Real Utility</h2>
        <p>
          Our ecosystem offers genuine utility through a robust platform that supports practical and enjoyable use cases. The integration of NFTs and tokens ensures that every action has real, measurable benefits.
        </p>
      </section>

      <section style={{ marginBottom: '1.5rem' }}>
        <h2>Ambitious Open Source Video Game</h2>
        <p>
          We are developing a community-driven video game that is open source and highly ambitious. Our goal is to reconstruct both extinct and extant animals and plants, blending cutting-edge technology with creative storytelling. This project is driven by a passion for innovation and a commitment to community involvement.
        </p>
      </section>

      <section style={{ marginBottom: '1.5rem' }}>
        <h2>Core Values</h2>
        <ul>
          <li>Hard Work & Consistent Education</li>
          <li>Advancement in Science & Art</li>
          <li>Community-Driven Innovation</li>
        </ul>
        <p>
          At the heart of our white paper is the belief that a commitment to education, science, Free Speech, Decentralizatoion, and artistic expression can transform how technology serves society. Our vision is to create a future where learning and earning go hand in hand.
        </p>
      </section>
    </div>
  );
};

export default WhitePaper;
