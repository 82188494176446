// src/PrivacyPolicy.tsx

import React from 'react';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy-container" style={{ padding: '2rem', maxWidth: '800px', margin: '0 auto', fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
      <h1>Privacy Policy</h1>
      <p><em>Last Updated: 03-20-2025</em></p>

      <h2>1. Information Collection</h2>
      <p>
        We collect personal information that you voluntarily provide to us when you register on our website, subscribe to our newsletter, fill out a form, or otherwise interact with us. This information may include your name, email address, and any other details you choose to provide.
      </p>

      <h2>2. Use of Information</h2>
      <p>
        Your personal information is used for the following purposes:
      </p>
      <ul>
        <li>To provide, maintain, and improve our website and services.</li>
        <li>To send you newsletters, marketing communications, and important updates.</li>
        <li>To respond to your inquiries and provide customer support.</li>
      </ul>
      <p>
        By using our website, you consent to our use of your information for these purposes.
      </p>

      <h2>3. Cookies and Tracking Technologies</h2>
      <p>
        We use cookies and similar tracking technologies to enhance your experience, analyze website traffic, and personalize content. Cookies are small files stored on your device. You can manage your cookie preferences through your browser settings.
      </p>

      <h2>4. Information Sharing</h2>
      <p>
        We do not sell or rent your personal information to third parties for marketing purposes. However, we may share your information with trusted third-party service providers who assist us in operating our website and conducting our business, under strict confidentiality agreements.
      </p>

      <h2>5. Data Security</h2>
      <p>
        We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.
      </p>

      <h2>6. Your Rights</h2>
      <p>
        You have the right to access, correct, or delete your personal information, subject to applicable law. If you wish to exercise these rights, please contact us using the details provided below.
      </p>

      <h2>7. Changes to This Privacy Policy</h2>
      <p>
        We reserve the right to update or modify this Privacy Policy at any time without prior notice. Changes will be effective immediately upon posting the updated policy on our website. Your continued use of our website constitutes your acceptance of any such changes.
      </p>

      <h2>8. Consent</h2>
      <p>
        By using our website, you agree to the collection and use of your information as described in this Privacy Policy. You also consent to receive emails and accept the use of basic cookies as outlined above.
      </p>

      <h2>9. Contact Us</h2>
      <p>
        If you have any questions about this Privacy Policy or our data practices, please contact us at <a href="mailto:joe.turner.writer@gmail.com">joe.turner.writer@gmail.com</a>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
